.legend {
	display: none;
}

.show .legend,
.focus .legend,
.dropdown:hover .legend,
.multiple-typeahead:hover .legend,
.multiple-typeahead:focus-within .legend,
.input-group:hover .legend,
.input-group:focus-within .legend {
	display: inline-block;
	position: absolute;
	border: 2px $primary solid;
	border-radius: $border-radius;
	height: calc(100% + #{$font-size-sm / 2} + 4px);
	width: 100%;
	font-size: $font-size-sm;
	padding: 0 1rem;
	top: calc(-#{$font-size-sm / 2} - 4px);
	pointer-events: none;

	legend {
		font-size: $font-size-sm;
		width: auto;

		span {
			padding: 0 0.5rem;
		}
	}
}
