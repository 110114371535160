.show .dropdown-toggle,
.dropdown-toggle:hover .dropdown-toggle,
.dropdown-toggle:hover {
	background-color: transparent !important;
	color: unset !important;
	border-color: transparent !important;

	&::after {
		color: $primary;
	}
}
