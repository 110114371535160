.modal {
	$this: &;
	&-fullsize {
		max-width: calc(100vw - 3rem);
		#{$this}-body {
			height: calc(100vh);
		}
	}
	&.fade {
		transform: scale(0.6);
		opacity: 0;
		transition: all 0.2s linear;
		&.show {
			opacity: 1;
			transform: scale(1);
		}
	}
}
