::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
	opacity: 0;
}

::-webkit-scrollbar,
.custom-scrollbar > div:last-child {
	background-color: $gray-07 !important;
	border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb,
.custom-scrollbar > div:last-child div {
	background-color: $gray-09 !important;
	border-radius: 0.5rem;
	cursor: pointer;
}
