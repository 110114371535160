.no-scroll .PdfHighlighter {
	overflow: hidden;
}

::selection {
	background: $pdf-selection-color !important;
	color: $color-primary;
}

*::-moz-selection {
	background: $pdf-selection-color !important;
	color: $color-primary;
}

.textLayer ::selection {
	color: unset !important;
}

.PdfHighlighter__highlight-layer {
	top: 0;
}

.textLayer .highlight {
	background-color: rgba(0, 0, 0, 0) !important;
}

.textLayer .markedContent {
	position: unset;
}

.pdfViewer .page {
	will-change: opacity;
}

.pdfViewer.removePageBorders .page:last-child {
	margin-bottom: 0;
}

.is-processing {
	.pdf-viewer {
		opacity: 0.8;
	}
}

.pdf-detail {
	.Highlight__part {
		pointer-events: none;
	}

	.Highlight--scrolledTo .Highlight__part {
		border-width: 3px;
		border-style: solid;
	}

	.progress {
		height: 0.2rem;
		border-radius: 0;
	}
}
