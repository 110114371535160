.search-typeahead {
	.input-group-prepend {
		position: absolute;
		height: 100%;
	}

	.rbt {
		padding-left: $typeahead-padding-left;
	}

	.search-icon {
		width: 1.5rem;
		height: 1.5rem;
		margin: auto;
	}
}

.rbt {
	position: relative;
	display: flex;
	align-items: center;
	border: 1px solid $gray-12;
	border-radius: $border-radius;
	height: 3.5rem;

	&:hover,
	&:focus-within {
		border-color: $primary;
	}

	// Hide IE's native "clear" button
	.rbt-input-main::-ms-clear {
		display: none;
	}

	.rbt-input-multi {
		height: 60px;
		border: none;
		overflow: hidden;
		position: relative;

		.rbt-input-wrapper {
			display: flex;
			align-items: center;
			overflow-x: auto;
			overflow-y: hidden;
			padding-bottom: 0.1rem;

			.rbt-input-main {
				color: $color-white;
				line-height: 1.875rem;
			}

			.rbt-input-hint {
				color: $color-white-blur !important;
				line-height: 1.875rem;
			}

			div:last-child {
				min-width: 15rem;
			}
		}
	}

	.rbt-aux {
		position: absolute;
		right: 0;
		margin-right: 0.5rem;

		.close {
			opacity: 1;
		}
	}

	.rbt-token {
		border: 0;
		border-radius: 1rem;
		display: inline-block;
		padding: 0 0.625rem;
		margin-right: 0.625rem;
		display: flex;
		align-items: center;
		white-space: nowrap !important;
		outline: none;
		border: 2px transparent solid;

		@for $i from 1 through length($tag-colors) {
			$color: nth($tag-colors, $i);
			&:nth-child(#{$i}) {
				background-color: $color;

				.rbt-token-remove-button span:nth-child(1) {
					color: $color;
				}
			}
		}

		&.rbt-token-active {
			border: 2px $primary solid;
		}

		.rbt-token-remove-button {
			margin-left: 0.5rem;
			display: flex;
			opacity: unset;
			border-radius: 50%;
			width: 0.875rem;
			height: 0.875rem;
			line-height: 0.8rem;
			align-items: center;
			justify-content: center;
			background-color: $color-white;

			span:nth-child(1) {
				display: inline-block;
				font-size: 18px;
				font-weight: 900;
			}
		}
	}

	.form-control {
		border: none;

		&:focus {
			background-color: transparent;
			border-color: transparent;
			outline: 0;
			box-shadow: none;
		}
	}
}

.multiple-typeahead {
	position: relative;

	&.focus,
	&:hover {
		.rbt {
			border-color: $primary !important;

			.close {
				color: $primary !important;
			}
		}
	}

	.typeahead-error {
		width: 100%;
		position: absolute;
	}
}

#duplicate-keyword-tooltip {
	.arrow {
		transform: translate(1rem, 0px) !important;
	}
}
