.loading-dots {
  position: absolute;
  width: 100%;
  height: 16.25rem;
  margin-top: -16.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.loading-dots div {
  width: 14px;
  height: 14px;
  border-radius:50%;
  float: left;
  margin: 0 3px;
  background: #ffffff;
  transform: scale(0);
  animation: grow 2.2s infinite ease-in-out;
}

.loading-dots .dot-1 {
  z-index: 1;
}

.loading-dots .dot-2 {
  animation-delay: 0.15s;
}

.loading-dots .dot-3 {
  animation-delay: 0.3s;
}

@keyframes grow {
  0% {transform: scale(0);}
  25% {transform: scale(1);}
  50% {transform: scale(0);}
  75% {transform: scale(1);}
  100% {transform: scale(0);}
}