$primary: #445ffe;
$secondary: #6c757d;
$success: #198754;
$danger: #da1a5d;
$disabled: #6c757d;
$lightBackground: #ffffff;

$primaryTextColor: #333333;
$secondTextColor: #777777;
$dangerColor: #da1a5d;
$brandText: #828282;
$formLabelColor: #797979;
$borderColor: #e8e8e899;
$tagDefaultColor: #eeeeee;

$color-primary: #000000;
$color-disabled: #495057;
$color-white: #ffffff;
$color-white-blur: #ffffffcb;
$color-white-lable: #ffffffde;
$color-red: rgba(255, 71, 71, 0.75);
$color-blue: #9fadff;

$gray-01: #191919;
$gray-02: #262626;
$gray-03: #333333;
$gray-04: #434343;
$gray-05: #4f4f4f;
$gray-06: #565656;
$gray-07: #616161;
$gray-08: #828282;
$gray-09: #bdbdbd;
$gray-10: #e0e0e0;
$gray-11: #f2f2f2;
$gray-12: #ffffff99;

$body-bg: $gray-02;
$body-color: $color-white;

$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;
$font-size-exsm: $font-size-base * 0.75;
$font-weight-medium: 500;

$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'success': $success,
);

$tag-colors: rgba(255, 71, 71, 0.75), rgba(255, 191, 71, 0.75), rgba(246, 81, 166, 0.75), rgba(71, 255, 71, 0.75),
	rgba(71, 71, 255, 0.75), rgba(178, 71, 255, 0.75), rgba(255, 71, 255, 0.75), rgba(150, 170, 197, 0.75),
	rgba(71, 234, 255, 0.75), rgba(183, 143, 154, 0.75), rgba(185, 246, 81, 0.75), rgba(255, 224, 71, 0.75),
	rgba(76, 76, 76, 0.75), rgba(245, 0, 0, 0.75), rgba(163, 106, 0, 0.75), rgba(245, 204, 0, 0.75), rgba(0, 245, 0, 0.75),
	rgba(0, 0, 245, 0.75), rgba(143, 0, 245, 0.75), rgba(245, 0, 245, 0.75), rgba(97, 128, 168, 0.75),
	rgba(0, 216, 245, 0.75), rgba(149, 95, 109, 0.75), rgba(152, 233, 12, 0.75), rgba(233, 12, 126, 0.75),
	rgba(163, 0, 0, 0.75), rgba(163, 106, 0, 0.75), rgba(163, 136, 0, 0.75), rgba(0, 163, 0, 0.75), rgba(0, 0, 163, 0.75),
	rgba(95, 0, 163, 0.75), rgba(163, 0, 163, 0.75), rgba(65, 88, 118, 0.75), rgba(0, 144, 163, 0.75),
	rgba(100, 64, 73, 0.75), rgba(101, 155, 8, 0.75), rgba(155, 8, 84, 0.75), rgba(82, 0, 0, 0.75), rgba(82, 53, 0, 0.75),
	rgba(82, 69, 0, 0.75), rgba(0, 82, 0, 0.75), rgba(0, 0, 82, 0.75), rgba(48, 0, 82, 0.75), rgba(82, 0, 82, 0.75),
	rgba(36, 49, 66, 0.75), rgba(0, 72, 82, 0.75), rgba(50, 32, 37, 0.75), rgba(50, 77, 4, 0.75), rgba(77, 4, 42, 0.75),
	rgba(204, 204, 204, 0.75);

$background-color-disabled: #e9ecef;

$border-color-focus: #80bdff;
$border-color-focus-invalid: #dc3545;
$border-color-focus-valid: #28a745;

$box-shadow-dimensions: 0 0 0 0.2rem;
$box-shadow-color: rgba(0, 123, 255, 0.25);
$box-shadow-color-invalid: rgba(220, 53, 69, 0.25);
$box-shadow-color-valid: rgba(40, 167, 69, 0.25);

$color-focus: #495057;
$placeholder-color: #6c757d;

$token-background-color: #e7f4ff;

$popover-font-size: $font-size-base;
$popover-bg: $gray-03;

$border-radius: 4px;
$btn-border-radius: $border-radius;

$btn-padding-x-lg: 1rem;
$btn-padding-y-lg: 1rem;
$btn-font-size-lg: $font-size-base;
$btn-line-height-lg: $font-size-base * 1.375;
$btn-border-radius-lg: $btn-border-radius;

$btn-padding-x-sm: 1.125rem;
$btn-padding-y-sm: 0.625rem;
$btn-font-size-sm: $font-size-sm;
$btn-line-height-sm: $font-size-sm;
$btn-border-radius-sm: $btn-border-radius;

$dropdown-bg: $gray-03;
$dropdown-link-color: $color-white;
$dropdown-padding-y: 0;
$dropdown-item-padding-x: 1rem;
$dropdown-item-padding-y: 0.3rem;
$dropdown-link-hover-bg: $color-white;
$dropdown-link-active-bg: $color-white;
$dropdown-link-hover-color: $gray-01;
$dropdown-link-active-color: $gray-01;

$input-btn-padding-x: 1rem;
$input-color: $color-white;
$input-bg: transparent;
$input-border-color: $gray-12;
$input-border-radius: $border-radius;
$input-padding-x: 1rem;
$input-padding-y: 0.9375rem;
$input-height-border: 0;

$close-text-shadow: none;
$close-color: $gray-08;

$detail-content-color: $gray-04;
$detail-body-color: $gray-05;
$detail-title-height: 3.75rem;
$detail-toolbar-height: 3.5rem;
$detail-toolbar-border-color: $gray-07;
$detail-slider-color: #9fadfc;
$detail-slider-track-color: $gray-08;
$detail-slider-label-color: $color-white-lable;
$detail-slider-label-font-size: $font-size-exsm;

$tooltip-background-color: $gray-01;
$tooltip-font-color: $color-white-lable;
$tooltip-font-size: $font-size-exsm;

$left-side-bar-width: 17rem;

$search-result-grid-gutter-width: 1rem;

$pdf-selection-color: #3390ff;

$icon-hover-brightness: 70%;

$typeahead-padding-left: 2.125rem;

$progress-height: 0.5rem;
