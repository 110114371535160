.badge {
	font-size: $font-size-base;
	font-weight: normal;
	border-radius: 1rem;
	display: inline-block;
	padding: 0 0.625rem;
	line-height: 1.875rem;

	&.badge-primary {
		background-color: $primary;
	}

	&.badge-secondary {
		background-color: $gray-05;
	}
}
