.footer {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	font-size: $font-size-sm;
	color: $color-white-blur;

	.container-fluid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 0;
		border-top: 2px $borderColor solid;
	}

	a {
		color: $color-white-blur;
		text-decoration: none;
		background-color: transparent;

		&:hover {
			color: $color-white;
		}
	}

	.powered-by {
		display: flex;
		gap: 6px;

		img {
			margin-top: -1px;
		}
	}
}
