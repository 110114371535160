.search-result {
  max-width: 100%;

  .search-result-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    margin-top: -$search-result-grid-gutter-width / 2;
    margin-left: -$search-result-grid-gutter-width / 2;

    .col {
      padding: $search-result-grid-gutter-width / 2;
    }
  }

  .item {
    .content {
      border-radius: 4px 4px 0 0;
      height: 13rem;
      background-color: $gray-03;
      overflow: hidden;
    }

    .thumb-details {
      border-radius: 0 0 4px 4px;
      padding: 1rem;
      background-color: $gray-04;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .thumb-details__header {
        display: flex;
        justify-content: space-between;
        gap: 12px;

        .title {
          font-weight: bold;
          display: flex;
          word-break: break-all;
          flex-grow: 1;

          p {
            text-transform: uppercase;
          }
        }

        .thumb-details__menu {
          position: relative;
          > svg {
            transition: 0.2s ease;
            cursor: pointer;
            user-select: none;
            &:hover {
              opacity: 0.6;
            }
          }

          &.is-open > svg {
            pointer-events: none;
          }

          > div {
            position: absolute;
            background: $gray-03;
            border-radius: 4px;
            width: 180px;
            top: 32px;
            right: -2px;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
            transform-origin: top right;
            animation: open-menu 0.15s ease;
            display: flex;
            flex-direction: column;
            z-index: 999;
            overflow: hidden;
            padding: 6px 0;

            > div {
              height: 40px;
              padding: 0.4rem;
              display: flex;
              align-items: center;
              gap: 0.4rem;
              cursor: pointer;
              transition: 0.2s ease;
              &:hover {
                background: $gray-04;
              }
            }
          }
        }
      }

      .thumb-details__content {
        margin-top: 0.8rem;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        color: $gray-09;

        strong {
          display: block;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 12px;
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        .thumb-details__revision {
          display: flex;

          div:not(:first-child) {
            margin-left: 72px;
          }
        }
      }
    }
  }
}

@keyframes open-menu {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
