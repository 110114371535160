.ic {
	cursor: pointer;

	&:hover {
		filter: brightness($icon-hover-brightness);
	}
	&.active {
		fill: $primary;
	}
	&.inactive {
		pointer-events: none;
	}
}

.ic-small {
	width: 0.75rem;
	height: 0.75rem;
}

.ic-medium {
	width: 1rem;
	height: 1rem;
}

.ic-large {
	width: 1.5rem;
	height: 1.5rem;
}
