.detail-page {
	.modal-title-fluid {
		width: 100%;
	}

	.modal {
		&-content {
			height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
			background-color: $detail-content-color;
		}
		&-header {
			padding: 0;
			border-bottom: none;
		}
		&-body {
			background-color: $detail-body-color;
		}
	}

	.title {
		display: flex;
		height: $detail-title-height;
		align-items: center;

		.ic.close-modal-icon {
			margin-right: 1rem;
		}
	}

	.toolbar {
		display: flex;
		height: $detail-toolbar-height;

		@media (max-width: 1920px) {
			.selected-keywords {
				width: 72%;
			}
			
			.numeric-input {
				width: 17%;
			}
		}
		@media (min-width: 1920px) {
			.selected-keywords {
				width: 80%;
			}
			
			.numeric-input {
				width: 12%;
			}
		}

		> * {
			display: flex;
			align-items: center;
			border: 1px solid $detail-toolbar-border-color;
		}

		.selected-keywords {
			.keyword {
				display: inline-grid;
				.content {
					overflow-x: auto;
					overflow-y: hidden;
				}
			}
		}
		.fit-viewer {
			justify-content: center;
			border-width: 1px 0 1px 0;

			.ic {
				height: 2.5rem;
			}
		}

		&.disabled {
			svg,
			.input-range__track,
			.input-range__slider {
				opacity: $btn-disabled-opacity;
				pointer-events: none;
			}
		}
	}
}
