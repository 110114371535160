#root {
	height: 100%;
}
.app-logo {
	width: 13rem;
	height: 3.125rem;

	&-sm {
		width: 9.25rem;
		height: 2.25rem;
	}

	&-lg {
		width: 22.5rem;
		height: 5.375rem;
	}
}

.s {
	$this: &;
	&-brand {
		&--center {
			text-align: center;
			margin-bottom: 3rem;
		}
		&-logo {
			margin-bottom: 0;
		}
		&-text {
			font-size: 1.875rem;
			font-weight: bold;
			color: $brandText;
		}
	}

	&-page-form {
		&--center {
			& {
				width: 33.5rem;
			}
			#{$this}-group-btn {
				text-align: center;
				margin-top: 2.5rem;
				.btn-on-page {
					width: 17.9375rem;
					height: 5.125rem;
					font-size: 1.25rem;
					font-weight: bold;
					border-radius: 5.125rem;
				}
			}
		}
		&--processing {
			.btn-on-page {
				width: auto;
				pointer-events: none;
				width: 5.125rem;
				&:focus {
					box-shadow: none;
				}
			}
		}
		&-title {
			margin-bottom: 2.5rem;
			h3 {
				font-size: 1.625rem;
				font-weight: bold;
			}
			&--sub {
				font-size: 1.25rem;
			}
		}

		.custom-checkbox {
			padding-left: 2.5rem;
			min-height: 2.5rem;
			line-height: 2.3rem;
			input[type='checkbox'] {
				height: 2.25rem;
				width: 2rem;
			}
			.custom-control-label {
				&::before,
				&::after {
					height: 2rem;
					width: 2rem;
					left: -2.5rem;
				}
			}
		}
	}

	&-pager {
		ul {
			display: flex;
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				cursor: pointer;
				margin-right: 1rem;
				width: 1.875rem;
				line-height: 1.875rem;
				font-size: 1rem;
				text-align: center;
				border-radius: 2.5rem;
				color: $primaryTextColor;
				&:hover {
					background-color: #f2f2f2;
				}
				&.active {
					background-color: $primary;
					color: #ffffff;
				}
				&.active:hover {
					background-color: #082a6e;
				}
			}
		}
	}

	&-tags {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		.item {
			font-size: $font-size-sm;
			padding: 0.2rem 0.6rem;
			background-color: $tagDefaultColor;
			border-radius: 1.25rem;
			margin: 0.25rem;
			display: inline-flex;
			&:first-child,
			&:last-child {
				margin-left: 0;
			}
			.custom-control-label {
				cursor: pointer;
				&::before {
					top: 0.45rem;
					box-shadow: none;
					border: 1px solid $white;
					background-color: transparent;
				}
				&::after {
					top: 0.45rem;
					box-shadow: none;
				}
			}
			.custom-control-input {
				&:focus:not(:checked) ~ .custom-control-label::before {
					border-color: $borderColor;
					background-color: $white;
				}
				&:not(:disabled):active ~ .custom-control-label::before {
					border-color: $borderColor;
					background-color: $white;
				}
				&:not(:checked) ~ .custom-control-label::before {
					border-color: $borderColor;
					background-color: $white;
				}
			}
		}
		&.custom {
			align-items: center;
			max-height: 48px;
			width: max-content;
		}
	}
}

.ic {
	&::before {
		content: '';
		display: inline-block;
		background-size: 100%;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&.angle-down {
		&::before {
			background-image: url(../../img/ic-angle-down.svg);
			width: 0.991875rem;
			height: 0.495625rem;
		}
	}
	&.angle-up {
		&::before {
			background-image: url(../../img/ic-angle-up.svg);
			width: 0.991875rem;
			height: 0.495625rem;
		}
	}
	&.file {
		&::before {
			background-image: url(../../img/ic-file.svg);
			width: 3rem;
			height: 3rem;
		}
	}
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.line-clamp-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.line-clamp-8 {
	display: -webkit-box;
	-webkit-line-clamp: 8;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.input-group-prepend .input-group-text svg {
	width: 16px;
	height: 16px;
}

#custom-close-button {
	width: 28px;
	height: 28px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 5px;
	cursor: pointer;
}

#custom-close-button.hide {
	visibility: hidden;
}

.typeahead-input-with-clear-btn {
	width: 100%;
	position: relative;
}

button {
	&.btn {
		box-shadow: none !important;
	}
}

.tooltip {
	.arrow::before {
		border-bottom-color: $tooltip-background-color;
	}
	.tooltip-inner {
		max-width: 500px;
		background-color: $tooltip-background-color;
		color: $tooltip-font-color;
		font-size: $tooltip-font-size;
	}
}
