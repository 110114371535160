.p-profile {
	text-align: center;
	.container {
		background-color: #ffffff;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
