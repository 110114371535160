html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}

hr {
	border-color: $gray-04;
}
