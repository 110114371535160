.numeric-input {
	padding-left: 0.8rem;
	padding-right: 0.8rem;
	width: 100%;

	.range-slider {
		display: flex;
		align-items: center;
		min-width: 80%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;

		.input-range {
			&__track {
				height: 0.12rem;

				&--active {
					background-color: $detail-slider-color;
					border-color: $detail-slider-color;
				}
				&--background {
					margin-top: -0.05rem;
					background-color: $detail-slider-track-color;
				}
			}

			&__slider {
				width: 0.9rem;
				height: 0.9rem;
				margin-top: -0.52rem;
				background-color: $detail-slider-color;
				border-color: $detail-slider-color;
			}

			&__label {
				color: $detail-slider-label-color;
				font-size: $detail-slider-label-font-size;
				&--min {
					display: none;
				}
				&--max {
					display: none;
				}
				&--value {
					top: -1.65rem !important;
				}
			}
		}
	}
}
