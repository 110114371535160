.input-group {
	&.input-group-search {
		.search-icon {
			position: absolute;
			width: 3rem;
			height: 100%;
			border: 1px solid #ced4da;
			border-right: none;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.form-control {
			border-left: none;
			// padding: 0.5rem 2.5rem 0.5rem 3rem;
			min-height: 3.4rem;

			&:focus {
				box-shadow: none;
				border-color: #ced4da;
			}
		}
	}
	flex-wrap: nowrap;
}
