.header {
	display: flex;
	align-items: center;
	padding: 0 1.5rem;
	min-height: 3.5rem;
	height: 3.5rem;
	
	a {
		display: flex;
		align-items: center;
		gap: 10px;
		text-decoration: none;
		color: #fff;
		font-size: 20px;
	}
}

#popover-user-info {
	width: 17rem;

	.arrow::after {
		display: none;
	}
}
