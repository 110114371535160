.pager {
	ul {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			cursor: pointer;
			margin-right: 1rem;
			width: 1.875rem;
			line-height: 1.875rem;
			font-size: 1rem;
			text-align: center;
			&:hover {
				filter: brightness($icon-hover-brightness);
			}
			&.active {
				color: $color-blue;
			}
		}
	}
}
