.search-box {
	&:not(.search-box-full) {
		max-width: 55rem;
	}

	.dropdown-toggle,
	.dropdown-menu {
		width: 12rem;
		text-align: left;
		text-transform: unset;

		&::after {
			position: absolute;
			top: 1.5rem;
			right: 1rem;
			color: $gray-08;
			font-size: 1.125rem;
		}

		@media (max-width: 1366px) {
			max-height: 15rem !important;
		}
	}
}
